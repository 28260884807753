import DomesticTicketAirportSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/domestic-ticket-airport-selector/1.2.4/index.vue';// 机票选择组件
const _ = require(`underscore`);
import CalendarBox from "@/page/distributor/air-ticket/components/calendar/index.vue";
import airLineBox from "@/page/distributor/air-ticket/components/airLine";
import moment from "moment";
import AirCity from "@/page/distributor/air-ticket/components/airCityAddress";
import SortBox from "@/page/distributor/air-ticket/components/sortBox";
import ImgLogo from "@/page/distributor/air-ticket/components/airImgLogo";
import ChangClause from "@/page/distributor/air-ticket/components/changClause";
import LuggageClause from '@/page/distributor/air-ticket/components/luggageClause/index.vue'
import AirStop from "@/page/distributor/air-ticket/components/airStop";

const citys = require(`@/lib/data-service/flight/flight_domestic_citys`);// 国内航空公司查询
const airlines = require(`@/lib/data-service/flight/flight_domestic_airlines`);// 国内航班列表查询
import buyer_dom_white_searchFlight from '@/lib/data-service/flight/buyer_dom_white_searchFlight'
import buyer_dom_white_flightPrice from '@/lib/data-service/flight/buyer_dom_white_flightPrice'
import buyer_dom_white_detail from '@/lib/data-service/flight/buyer_dom_white_detail'
import buyer_dom_white_searchFlightLimitRule from '@/lib/common-service/buyer_dom_white_searchFlightLimitRule'
import buyer_dom_white_rule from '@/lib/common-service/buyer_dom_white_rule'
import buyer_dom_white_luggage from '@/lib/common-service/buyer_dom_white_luggage'

//import rule from "@/lib/data-service/flight/newFligthApi/buyer_dom_white_rule";
//import luggage from "@/lib/data-service/flight/buyer_dom_white_luggage";
export default {
    data() {
        return {
            pickerOptions: this.setEndTime1(),
            arrowsShow: null,
            flightSartSort: true,
            flightEndSort: true,
            airType: 1, // 航程类型
            clauseShow: null,
            airTypeShow: true,
            loadingShow: 0,
            pickerOptions2: this.setEndTime(), //  返回时间
            time1: null, //  出发时间
            time2: null, //  返回时间
            time2Show: true, // 返回时间是否可用
            timeRoll: 1569772800000, //滚动条时间
            startCityObj: {},
            starCity: "", // 出发城市
            starCity1: "", // 展示出发城市
            starTwoCity: "", // 第二出发城市
            starTwoCity1: "", // 第二展示出发城市
            endCityObj: {},
            endCity: "", // 目的城市
            endCity1: "", // 展示目的城市
            endTwoCity: "", // 第二目的城市
            endTwoCity1: "", // 第二展示目的城市
            cityList: [], // 城市字母列表
            restaurants: [], // 所有城市机场列表
            cityListH: ["热门", "ABCD", "EFGH", "JKLM", "NOPQRS", "TUVWX", "YZ"], // 热门列表
            HotCity: [], //  热门城市列表
            cityListHShow: 0, //  热门列表index
            starList: false, //  出发城市列表显示
            EndList: false, //  目的城市列表显示
            starTwoList: false, //  出发城市列表显示
            EndTwoList: false, //  目的城市列表显示
            ClassType: 0, //  舱位选择
            typeShow: 1,
            creditState: false,
            ClassTypeList: [
                //  舱位列表
                {
                    value: 0,
                    label: "不限"
                },
                {
                    value: 1,
                    label: "头等舱"
                },
                {
                    value: 2,
                    label: "公务舱"
                },
                {
                    value: 3,
                    label: "经济舱"
                }
            ],
            psgType: 1, //  乘机人类型
            airlines: "", //  航司
            airlinesList: [
                //  航司列表
                {
                    airlineCode: "",
                    airCompanyName: "不限"
                }
            ],
            timeIndex: 0, // 已选的class
            flightsList: [], // 航班列表
            loading: false, //  加载中
            flightsIndex: 0, //  当前展示的列表
            flightsShow: false, // 是否展示
            reserveType: "预订", // 按钮状态
            params: {
                takeOffInterval: ""
            }, // 搜索传参
            itemOne: [], //  第一行程
            image_url_airline_blank_map: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,
            weekList: [],
            weekBtnStatu: false,
            saveWeek: "",
            ruleText: [],
            ruleType: 0,
            ruleComment: [],
            prompts: [],
            clauseLoading: false,
            luggageLoading: false,
            luggage: "",
            ruleObj: {},
            segmentsList: [],
            initStatu: 0,
            takeOffTime: [
                {value: "", label: "全部"},
                {value: "00:00-05:59", label: "00:00-05:59"},
                {value: "06:00-11:59", label: "06:00-11:59"},
                {value: "12:00-17:59", label: "12:00-17:59"},
                {value: "18:00-23:59", label: "18:00-23:59"}
            ],

            dialogErrorVisible: false,
            dialogError2Visible: false,
            dialogError3Visible: false,
            errorText: '',
            errorCode: '',
            returnSecond: 3,
            returnSetInterval: '',
            formData: null,


            //arrowsShow: null,
            flightShow: [],
            checkedTax: false,
            //flightsIndex: null,
            settlementPrice: true,
            sortShow: [true, false, false],
            imageUrl: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,

            ageLimitText: '',
            showPsgType: 1,
            airStatus: 2,
            flightNo: '', // 航班号
            cabin: '', // 舱位
            accurateSearch: false, // 是否精确查询
        };
    },
    components: {
        CalendarBox,
        airLineBox,
        AirStop,
        AirCity,
        DomesticTicketAirportSelector,
        SortBox,
        ImgLogo,
        ChangClause, LuggageClause
    },
    methods: {
        showList(item, index) {
            item.moreState = !item.moreState;
            item.moreState
                ? (item.showListNum = this.flightsList[index].policys.length)
                : (item.showListNum = 6);
        },
        setSortActive(index) {
            if (this.flightsList.length === 0) return;
            if (!this.sortShow[index]) {
                this.sortShow.forEach((item, index) => {
                    this.$set(this.sortShow, index, false);
                });
                this.sortShow[index] = true;
            }
        },
        setSettlementPrice() {
            if (this.flightsList.length === 0) return;
            this.settlementPrice = !this.settlementPrice;
            this.flightsList.sort((a, b) => {
                if (this.settlementPrice) {
                    return (
                        a.rebatePrice -
                        b.rebatePrice
                    );
                } else {
                    return (
                        b.rebatePrice -
                        a.rebatePrice
                    );
                }
            });
        },
        showOrHide(index, item) {
            this.flightShow[index] ? this.$set(this.flightShow, index, false) : this.$set(this.flightShow, index, true)
            let isShow = this.flightShow[index]
            if (isShow) {
                this.reloadItem(index, item)
                // this.$emit('reloadItem', index, item)
            }
        },
        flightTimeSort(type) {
            if (this.flightsList.length == 0) return;
            if (type === "start") {
                this.flightSartSort = !this.flightSartSort;
                this.flightEndSort = !this.flightEndSort;
                // depTime
                this.timeSort(this.flightsList, "depTime", this.flightSartSort);
            } else {
                this.flightEndSort = !this.flightEndSort;
                this.flightSartSort = !this.flightSartSort;
                // arrTime
                this.timeSort(this.flightsList, "arrTime", this.flightEndSort);
            }
        },
        timeSort(list, name, arrangeType) {
            if (arrangeType) {
                list.sort((a, b) => {
                    let one = moment(this.time1).format("YYYY-MM-DD") + " " + a[name];
                    let two = moment(this.time1).format("YYYY-MM-DD") + " " + b[name];
                    return moment(one).valueOf() - moment(two).valueOf();
                });
            } else {
                list.sort((a, b) => {
                    let one = moment(this.time1).format("YYYY-MM-DD") + " " + a[name];
                    let two = moment(this.time1).format("YYYY-MM-DD") + " " + b[name];
                    return moment(two).valueOf() - moment(one).valueOf();
                });
            }
        },
        clickDate(val) {
            if (this.itemOne.length !== 0) {
                this.params.beginDate = val;
                this.time2 = new Date(val);
            } else {
                this.params.beginDate = val;
                this.time1 = new Date(val);
            }
            this.saveWeek = val;
            this.initStatu = 0;
            this.search();
        },
        initSearch(val) {
            this.dialogErrorVisible = false
            this.dialogError2Visible = false

            if ((!this.starCity || this.starCity === '') && (!this.endCity || this.endCity === '')) {
                this.$message({
                    type: "warning",
                    message: '请选择出发城市和目的城市'
                })
                return
            }
            if (!this.starCity || this.starCity === '') {
                this.$message({
                    type: "warning",
                    message: '请选择出发城市'
                })
                return
            }
            if (!this.endCity || this.endCity === '') {
                this.$message({
                    type: "warning",
                    message: '请选择目的城市'
                })
                return
            }

            val == "amend"
                ? ((this.itemOne.length = 0), (this.segmentsList.length = 0))
                : ((this.itemOne.length = 0), (this.segmentsList.length = 0));
            if (this.itemOne.length > 0) {
                this.saveWeek = this.$moment(this.time2).format("YYYY-MM-DD");
            } else {
                this.saveWeek = this.$moment(this.time1).format("YYYY-MM-DD");
            }
            this.initStatu++;
            this.search();
        },
        // 日期选择
        setEndTime() {
            let _this = this;
            return {
                disabledDate: time => {
                    if (_this.time1) {
                        return time.getTime() < _this.time1;
                    } else {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    }
                }
            };
        },
        setEndTime1() {
            let _this = this;
            return {
                disabledDate: time => {
                    if (_this.time2 && this.airType === 2) {
                        return (
                            time.getTime() > _this.time2 ||
                            time.getTime() < Date.now() - 1000 * 3600 * 24
                        );
                    } else {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    }
                }
            };
        },
        querySearch(queryString, cb, name) {
            var res = this.restaurants;
            var results = queryString
                ? res.filter(this.createFilter(queryString))
                : "";
            // 调用 callback 返回建议列表的数据
            results.length === 0 ? (this[name] = "") : "";
            cb(results);
        },
        // createFilter(queryString) {
        //   return res => {
        //     if (res.spell.toUpperCase() === queryString.toUpperCase()) {
        //       return res;
        //     } else if (res.name === queryString) {
        //       return res;
        //     } else if (res.code.indexOf(queryString.toUpperCase()) !== -1) {
        //       return res;
        //     } else if (res.cityCode.indexOf(queryString.toUpperCase()) !== -1) {
        //       return res;
        //     }
        //   };
        // },
        createFilter(queryString) {
            let pattern2 = new RegExp("[A-Za-z]+");
            if (pattern2.test(queryString)) {
                return restaurant => {
                    if (restaurant.spell && restaurant.code) {
                        if (restaurant.code.indexOf(queryString.toUpperCase()) != -1) {
                            return restaurant;
                        } else if (
                            restaurant.spell
                                .toUpperCase()
                                .indexOf(queryString.toUpperCase()) != -1
                        ) {
                            return restaurant;
                        }
                    } else {
                        return "";
                    }
                };
            } else {
                return restaurant => {
                    if (restaurant.cityName.indexOf(queryString) !== -1) {
                        return restaurant;
                    } else {
                        return "";
                    }
                };
            }
        },
        selectItem(val) {
            this.airlines = val;
        },
        // 获取城市接口
        loadAllCity() {
            citys().then(res => {
                this.restaurants = [...res.citys];
                let cityList = [];
                for (let i = 0; i < res.citys.length; i++) {
                    if (res.citys[i].airports === false) {
                        cityList.push(res.citys[i]);
                    }
                    if (
                        res.citys[i].isHot === "Y" &&
                        this.HotCity.length < 23 &&
                        res.citys[i].airports === false
                    ) {
                        this.HotCity.push(res.citys[i]);
                    }
                }
                this.cityList = _.chain(cityList)
                    .groupBy(function (m) {
                        return m.initial;
                    })
                    .value();
                this.restaurants = this.setCityName(this.restaurants, cityList);
                // console.log("城市", this.restaurants);
            });
        },
        //  _getWeek(val){
        //         let arr = []
        //         val?val:''
        //         for(let i = 0;i<7;i++){
        //             let dateTime = this.$moment(val).subtract(-i, 'days').format('YYYY-MM-DD')
        //             let week = this.$moment(val).subtract(-i, 'days')._d
        //             week = week.getDay()
        //            week =  week===0?'星期日':week===1?'星期一':week===2?'星期二':week===3?'星期三':week===4?'星期四':week===5?'星期五':'星期六'
        //             let obj = {
        //                 week:week,
        //                 date:dateTime,
        //                 statu:false,
        //             }
        //             arr.push(obj)
        //         }
        //         this.weekList = arr
        //         if(this.saveWeek){
        //           arr.map((item,index)=>{
        //               item.date===this.saveWeek?
        //               item.statu = true:''
        //           })
        //         }
        //     },
        // 城市转换
        transitionCity() {
            let startCityObj = JSON.parse(JSON.stringify(this.startCityObj))
            let endCityObj = JSON.parse(JSON.stringify(this.endCityObj))
            this.endCityObj = startCityObj
            this.startCityObj = endCityObj

            let city = this.starCity;
            this.starCity = this.endCity;
            this.endCity = city;
            let city1 = this.starCity1;
            this.starCity1 = this.endCity1;
            this.endCity1 = city1;
        },
        transitionCity2() {
            let city = this.starTwoCity;
            this.starTwoCity = this.endTwoCity;
            this.endTwoCity = city;
            let city1 = this.starTwoCity1;
            this.starTwoCity1 = this.endTwoCity1;
            this.endTwoCity1 = city1;
        },
        ransitionCity2() {
            let city = this.starTwoCity;
            this.starTwoCity = this.endTwoCity;
            this.endTwoCity = city;
            let city1 = this.starTwoCity1;
            this.starTwoCity1 = this.endTwoCity1;
            this.endTwoCity1 = city1;
        },
        // 获取出发城市
        getstarCity(item) {
            this.starCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.starCity1 = item.name + "(" + item.code + ")";
            console.log(item, this.starCity);
        },
        getstarTwoCity(item) {
            this.starTwoCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.starTwoCity1 = item.name + "(" + item.code + ")";
            console.log(item);
        },

        getWeekDate(timeDay) {
            var now = new Date(timeDay);
            var day = now.getDay();
            var weeks = new Array(
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六"
            );
            var week = weeks[day];
            return week;
        },
        setCityName(restaurants, cityList) {
            let airList = restaurants.filter(item => {
                item.cityName = item.name;
                return item.airports;
            });
            airList.map(item => {
                cityList.map(sitem => {
                    if (item.spell === sitem.spell) {
                        item.cityName = sitem.name;
                    }
                });
            });
            return restaurants;
        },
        // 获取目的城市
        getendCity(item) {
            this.endCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.endCity1 = item.name + "(" + item.code + ")";
            console.log(item);
        },
        getendTwoCity(item) {
            this.endTwoCity = item.airports
                ? item.code
                : item.cityCode
                    ? item.cityCode
                    : item.code;
            this.endTwoCity1 = item.name + "(" + item.code + ")";
            console.log(item);
        },
        // 获取点击的城市
        getClickCity(item, data) {
            // console.log(item)
            if (data === "starCity") {
                this.starCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.starCity1 = item.name + "(" + item.code + ")";
                this.starList = false;
            }
            if (data === "endCity") {
                this.endCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.endCity1 = item.name + "(" + item.code + ")";
                this.EndList = false;
            }
            if (data === "starTwoCity") {
                this.starTwoCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.starTwoCity1 = item.name + "(" + item.code + ")";
                this.starTwoList = false;
            }
            if (data === "endTwoCity") {
                this.endTwoCity = item.airports
                    ? item.code
                    : item.cityCode
                        ? item.cityCode
                        : item.code;
                this.endTwoCity1 = item.name + "(" + item.code + ")";
                this.EndTwoList = false;
            }
        },
        // 获取焦点显示热门的城市
        CityFocus(data) {
            if (this.starCity1 === "" && data === "starCity") {
                this.starList = true;
                this.EndList = false;
                this.starTwoList = false;
                this.EndTwoList = false;
            }
            if (this.endCity1 === "" && data === "endCity") {
                this.starList = false;
                this.EndList = true;
                this.starTwoList = false;
                this.EndTwoList = false;
            }
            if (this.starTwoCity1 === "" && data === "starTwoCity") {
                this.starList = false;
                this.EndList = false;
                this.starTwoList = true;
                this.EndTwoList = false;
            }
            if (this.endTwoCity1 === "" && data === "endTwoCity") {
                this.starList = false;
                this.EndList = false;
                this.starTwoList = false;
                this.EndTwoList = true;
            }
        },
        noneShow() {
            let Starfocus = document.getElementById("starCity");
            let endCity = document.getElementById("endCity");
            let StarTwofocus = document.getElementById("starTwoCity");
            let endTwoCity = document.getElementById("endTwoCity");
            if (StarTwofocus !== document.activeElement) {
                this.starTwoList = false;
            }
            if (endTwoCity !== document.activeElement) {
                this.EndTwoList = false;
            }
            if (Starfocus !== document.activeElement) {
                this.starList = false;
            }
            if (endCity !== document.activeElement) {
                this.EndList = false;
            }
        },
        // 阻止事件冒泡
        stop() {
        },
        // 获取航司列表
        getAirlines() {
            airlines({pageSize: 100000}).then(data => {
                // console.log(data)
                let airlinesList = [...this.airlinesList, ...data.pageResult.pageData];
                airlinesList.map(item => {
                    item.value = item.airCompanyName + "(" + item.airlineCode + ")";
                });
                this.airlinesList = airlinesList;
            });
        },
        getAirStatus() {
            setTimeout(()=>{
                if (this.$store.state.data_get_user_info != null && this.$store.state.data_get_user_info != 'undefined'){
                    this.airStatus = this.$store.state.data_get_user_info.airStatus
                }
            }, 2000)
        },
        // 搜索航班列表
        search() {
            let params = {
                // 航程类型
                flightType: this.airType === 2 ? 2 : 1,
                needPnr: 0,
                // 出发城市三字码。
                depCityPortCode: this.starCity,
                // 到达城市三字码。
                arrCityPortCode: this.endCity,
                // 出发日期
                depDate: this.$moment(this.saveWeek).format("YYYY-MM-DD"),
                // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
                cabinRank: this.ClassType,
                // 航空公司二字码
                airLineCode: this.airlines,
                // psgType
                psgType: this.psgType,
                //  是否直飞
                directFlight: 0,
                // 折扣
                discount: 0,
                takeOffInterval: this.params.takeOffInterval,
                flightNo: this.flightNo,
                cabin: this.cabin,
                accurateSearch: this.accurateSearch,
            };
            // this.timeRoll = this.time1
            this.params = Object.assign({}, params);
            this.showPsgType = this.psgType;
            this.typeShow = this.airType;
            // console.log(this.params)
            if (this.accurateSearch) {
                if (null == this.flightNo || "" == this.flightNo || "undefined" == this.flightNo) {
                    // global.alert(`精准查询航班号不能为空`);
                    this.$message({message: "请输入航班号", type: "warning"});
                    this.$refs.flightNo.focus();
                    return;
                } else if (null == this.cabin || "" == this.cabin || "undefined" == this.cabin) {
                    // global.alert(`精准查询舱位不能为空`);
                    this.$message({message: "请输入舱位", type: "warning"});
                    this.$refs.cabin.focus();
                    return;
                }
            }
            if (!this.time1)
                return this.$message({message: "请选择出发时间", type: "warning"});
            if (this.airType === 1) {
                this.reserveType = "预订";
            } else if (this.airType === 2 && (this.itemOne.length === 0 || this.itemOne[0] === null)) {
                this.reserveType = "选为去程";
            } else {
                this.reserveType = "选为回程";
            }
            if (this.airType === 2 && this.time2 === null) {
                return this.$message({
                    message: "请选择回程时间",
                    type: "warning"
                });
            }

            // this.saveWeek = this.params.depDate
            this.getflights(this.params);

            // 替换地址栏参数
            let adrParams = {
                ClassType: this.ClassType,
                airType: this.airType,
                airlines: this.airlines,
                endCity: this.endCity,
                endCity1: this.endCity1,
                psgType: this.psgType,
                starCity: this.starCity,
                starCity1: this.starCity1,
                time1: this.time1 ? moment(this.time1).valueOf() : '',
                time2: this.time2 ? moment(this.time2).valueOf() : '',
                time2Show: this.time2Show,
                flightNo: this.flightNo,
                cabin: this.cabin,
                accurateSearch: this.accurateSearch,
            }
            // 组装当前页面url
            let href = location.href.split('?')[0]
            let newUrl = href + '?airdata=' + JSON.stringify(adrParams)
            console.log('newUrl', newUrl)
            history.replaceState(null, null, newUrl)
        },

        // 获取航班列表
        getflights(params) {
            // console.log(params)
            this.loading = true;
            // this.flightsList = [];
            buyer_dom_white_searchFlight(params)
                .then(data => {
                    if (data.flights) {
                        this.loading = false;
                        let flightsList = data.flights
                        flightsList.forEach((value, index) => {
                            /*let rebatePrice = 0
                            let settlementPriceTaxes = 0
                            value.policys.forEach((value1, index1) => {
                                if (index1 === 0) {
                                    rebatePrice = value1.priceData[0].rebatePrice
                                    settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
                                } else {
                                    if (rebatePrice > value1.priceData[0].rebatePrice) {
                                        rebatePrice = value1.priceData[0].rebatePrice
                                    }
                                    if (settlementPriceTaxes > value1.priceData[0].settlementPriceTaxes) {
                                        settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
                                    }
                                }
                            })*/
                            value.moreState = false
                            value.showListNum = 6
                            value.rebatePrice = value.basePrics
                            value.settlementPriceTaxes = value.priceTaxes
                            // value.isReload = false
                            value.loading = false
                        })
                        this.flightsList = flightsList;
                        this.creditState = data.creditState;
                        this.airTypeShow = true;
                    } else {
                        this.flightsList = []
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.flightsList = []
                });
        },
        // 输出周几
        // Getweek (index) {
        //   let str = [
        //     '星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'
        //   ]
        //   let week = Number(this.$moment(this.timeRoll + (index * 1000 * 3600 * 24)).format('E')) - 1
        //   return str[week]
        // },
        // // 输出日期
        // getTime(index) {
        //   let day = 1000 * 3600 * 24
        //   let daynub = (this.timeRoll- - new Date(new Date().setHours(0,0,0,0))) / day
        //   if (daynub >= 3) {
        //     index = index - 2
        //     this.timeIndex = 2
        //     return this.$moment(this.timeRoll + (index * 1000 * 3600 * 24)).format('YYYY-MM-DD')
        //   } else {
        //     index = index - daynub
        //     this.timeIndex = daynub
        //     return this.$moment(this.timeRoll + (index * 1000 * 3600 * 24)).format('YYYY-MM-DD')
        //   }
        // },
        // 点击时间滚动条内容获取时间
        setTime(val) {
            this.weekList.map(item => {
                item.statu = false;
            });
            val.statu = true;
            this.saveWeek = val.date;
            this.weekBtnStatu = false;
            // this.timeRoll = this.timeRoll + ((index - this.timeIndex) * 1000 * 3600 * 24)
            if (this.itemOne.length !== 0) {
                console.log("回程1", this.$moment(this.time2).format("YYYY-MM-DD"));
                // this.time2 = this.timeRoll
                // this.params.sCityPortCode = this.endCity
                // this.params.eCityPortCode = this.starCity
                this.params.beginDate = this.$moment(this.time2).format("YYYY-MM-DD");
                this.time2 = new Date(val.date).valueOf();
                // this.params.beginDate = val.date
                this.getflights(this.params);
            } else {
                console.log("去程2");
                // this.time1 = this.timeRoll
                // this.params.beginDate = this.$moment(this.time1).format('YYYY-MM-DD')
                this.params.beginDate = val.date;
                this.getflights(this.params);
                this.time1 = new Date(val.date).valueOf();
            }
        },

        //退改规则
        backRule(val, policys) {
            this.ruleText = [];
            this.luggage = '';
            this.ruleObj = val;
            let data = {
                arrCityPortCode: val.arrAirportCode,
                cabinCode:
                    this.psgType == 2
                        ? policys.cabin.chdCabinCode
                        : policys.cabin.cabinCode,
                depCityPortCode: val.depAirportCode,
                depDate: val.depDate,
                flightNo: val.hasShare ? val.sharedFlightNo : val.flightNo,
                flightType: this.airType,
                psgType: this.psgType,
                supplierType: policys.supplierType || 1,
                policyId: policys.policyId,
                extData: policys.extData,
                businessExt: policys.businessExt,
            };
            this.getLuggage(data);
            this.clauseLoading = true
            buyer_dom_white_rule(data).then(res => {
                this.ruleType = res.ruleType
                let refComment = res.refRule.comment
                let chaComment = res.chaRule.comment
                let refRule = res.refRule.rules;
                let chaRule = res.chaRule.rules;
                if (refRule && chaRule) {
                    refRule.map(item => (item.type = "refRule"));
                    chaRule.map(item => (item.type = "chaRule"));
                    this.ruleText = [refRule, chaRule];
                }
                this.ruleComment = [refComment, chaComment]
                this.prompts = res.prompts || []
                // this.ruleText = res.chaRule.content;
            }).finally(() => {
                this.clauseLoading = false
            });
        },
        backLuggage(val, policys) {
            this.luggage = ''
            this.ruleObj = val;
            let data = {
                arrCityPortCode: val.arrAirportCode,
                cabinCode:
                    this.psgType === 2
                        ? policys.cabin.chdCabinCode
                        : policys.cabin.cabinCode,
                depCityPortCode: val.depAirportCode,
                depDate: val.depDate,
                flightNo: val.hasShare ? val.sharedFlightNo : val.flightNo,
                flightType: this.airType,
                psgType: this.psgType,
                supplierType: policys.supplierType || 1,
                policyId: policys.policyId,
                extData: policys.extData,
                businessExt: policys.businessExt,
            };
            this.getLuggage(data);
        },
        getLuggage(data) {
            this.luggageLoading = true
            buyer_dom_white_luggage(data).then(res => {
                this.luggageLoading = false
                this.luggage = res.luggage;
            }, error => {
                this.luggageLoading = false
            }).catch(() => {
                this.luggageLoading = false
            });
        },
        showAgeLimit(val, policys) {
            console.log(val, policys)
            this.ageLimitText = ''
            const params = {
                "businessExt": policys.businessExt,
                "depTime": val.depTime,
                "deptCode": val.depAirportCode,
                "deptDate": val.depDate,
                "destCode": val.arrAirportCode,
                "flightNo": val.flightNo,
                "policyId": policys.policyId,
                "supplierType": policys.supplierType
            }
            buyer_dom_white_searchFlightLimitRule(params).then(res => {
                let ageLimit = res.ageLimit
                let textArr = []
                ageLimit.forEach((value, index) => {
                    textArr.push(`${value.minAge}-${value.maxAge}${index + 1 !== ageLimit.length ? '、' : ''}`)
                })
                let ageLimitText = `限`
                textArr.forEach((value, index) => {
                    ageLimitText += value
                })
                ageLimitText += `岁预订`
                this.ageLimitText = ageLimitText
            })
        },

        // 行程选择
        journeyType(data, policys) {
            //机票业务未审核的账号不能点击预订按钮
            let isQrcode = this.airStatus === 0 ? true : false;
            if (isQrcode) {
                this.$alert('当前账号尚未开通该功能，请联系客户经理开通权限！', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                    }
                });
            }
            let item = {...data};
            let formData = {
                cabinRank: this.ClassType,
                directFlight: 0,
                flightType: this.airType,
                psgType: this.psgType,
                segments: [],

                arrAirportCode: data.arrAirportCode,
                depAirportCode: data.depAirportCode,
                depDate: data.depDate,
                depTime: data.depTime,
                arrDate: data.arrDate,
                arrTime: data.arrTime,
            };
            let segments = {
                productTypeTxt: policys.productTypeTxt,
                depAirportName: data.depAirportName,
                depAirportCode: data.depAirportCode,
                arrAirportCode: data.arrAirportCode,
                airCompanyName: data.airCompanyName,
                arrCityPortCode: data.arrAirportCode,
                arrCityName: data.arrCityName,
                cabinCode: policys.cabin.cabinCode,
                depCityPortCode: data.depAirportCode,
                depCityName: data.depCityName,
                depDate: data.depDate,
                flightNo: data.flightNo,
                policyId: policys.policyId,
                segmentType: this.segmentsList.length > 0 ? 2 : 1,
                supplierType: policys.supplierType || 1,
                businessExt: policys.businessExt,
                relaxPay: policys.relaxPay,

                "airCompanyCode": data.airCompanyCode,
                arrDate: data.arrDate,
                arrTime: data.arrTime,
                "chdCabinCode": policys.cabin.chdCabinCode,
                "depTime": data.depTime,
                "hasShare": data.hasShare,
                "priceData": policys.priceData,
                "productType": policys.policyType ? policys.policyType : 1,
                "sharedFlightNo": data.sharedFlightNo,
                "sharedAirCompanyCode": data.sharedFlightNo ? data.sharedFlightNo.substr(0, 2) : '',

                //providerWorkTime: policys.providerWorkTime,
                //refundTime: policys.refundTime,
                //comment: policys.comment
            };
            /**单程 */
            if (this.typeShow === 1) {
                formData.segments.push(segments);
                // 单程验舱验价
                console.log('单程验舱验价', formData)
                this.checkPriceAndGoNextPage(formData)

                return;
            }

            if (this.typeShow === 2) {
                if (this.segmentsList.length === 2 && this.segmentsList[0] === null) {
                    this.segmentsList[0] = segments
                } else {
                    this.segmentsList.push(segments);
                }
                formData.segments = this.segmentsList;
                console.log(formData);
                if (this.segmentsList.length === 2) {
                    item.policys = policys;
                    if (this.itemOne.length === 2 && this.itemOne[0] === null) {
                        this.itemOne[0] = item;
                    } else {
                        this.itemOne.push(item);
                    }

                    // 往返验舱验价
                    this.checkPriceAndGoNextPage(formData)
                    return;
                }


                this.reserveType = "选为回程";
                // console.log(this.params)
                item.policys = policys;
                this.itemOne.push(item);
                // console.log(this.itemOne)
                this.$forceUpdate();
                this.params.depCityPortCode = this.endCity;
                this.params.arrCityPortCode = this.starCity;
                this.saveWeek = this.params.depDate = this.$moment(this.time2).format(
                    "YYYY-MM-DD"
                );
                // this.timeRoll = this.time2
                this.loading = true;
                this.getflights(this.params);
                this.initStatu++;
                // this._getWeek(this.params.depDate)
            }

        },
        reloadItem(index, item) {
            if (item.policys && item.policys.length > 0) {
                return;
            }

            let params = {
                "arrAirportCode": item.arrAirportCode,
                "businessExt": item.businessExt,
                "deptAirportCode": item.depAirportCode,
                "deptDate": item.depDate,
                "deptTime": item.depTime,
                "flightNo": item.flightNo,
                "cabin": this.cabin,
                "psgType": this.psgType,
                "shareFlightNo": item.sharedFlightNo,
                cabinRank: this.ClassType
            }
            item.loading = true
            buyer_dom_white_flightPrice(params).then(res => {
                let policys = res.policys
                policys.airLineCode = res.flightNo.slice(0, 2)
                item.loading = false

                //item.ruleShow = 0
                item.showListNum = 6
                item.moreState = false

                let flightItem = this.flightsList[index]
                flightItem.policys = policys
                this.flightsList[index] = flightItem

                this.flightsList.forEach((value, index) => {
                    if (value.policys && value.policys.length > 0) {
                        let rebatePrice = 0
                        let settlementPriceTaxes = 0
                        value.policys.forEach((value1, index1) => {
                            if (index1 === 0) {
                                rebatePrice = value1.priceData[0].rebatePrice
                                settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
                            } else {
                                if (rebatePrice > value1.priceData[0].rebatePrice) {
                                    rebatePrice = value1.priceData[0].rebatePrice
                                }
                                if (settlementPriceTaxes > value1.priceData[0].settlementPriceTaxes) {
                                    settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
                                }
                            }
                        })
                        value.rebatePrice = rebatePrice
                        value.settlementPriceTaxes = settlementPriceTaxes
                    }
                })
            }, error => {
                item.loading = false
                let errorCode = error.data.code;
                this.errorText = error.data.msg;
                if (errorCode === '090002') {
                    this.dialogErrorVisible = true;
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.initSearch()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    this.dialogError2Visible = true
                }
            }).catch(() => {
                item.loading = false
            })


            /*if (item.isReload) {
                return;
            }
            let cabins = []
            item.policys.forEach((value, vIndex) => {
                if (value.supplierType === '7') {
                    let cabin = {
                        cabin: value.cabin.cabinCode,
                        policyNo: value.policyId
                    }
                    cabins.push(cabin)
                }
            })
            if (cabins.length === 0) {
                return
            }
            let params = {
                cabins,
                airLineCode: item.airCompanyCode,
                arrCityPortCode: item.arrAirportCode,
                depCityPortCode: item.depAirportCode,
                depDate: item.depDate,
                flightNo: item.flightNo,
                supplierType: '7',
                takeOffTime: item.depTime,
                shareFlightNo: item.sharedFlightNo
            }
            item.loading = true
            buyer_dom_white_updatePrice(params).then(res => {
                // let pIndex = 0
                let policys = res.policys
                item.loading = false
                if (policys.length === 0) {
                    return
                }
                let flightItem = this.flightsList[index]
                for (let i = 0, l = flightItem.policys.length; i < l; i++) {
                    if (flightItem.policys[i].supplierType === '7') {
                        const flightItemPolicyId = flightItem.policys[i].policyId
                        const flightItemCabin = flightItem.policys[i].cabin.cabinCode
                        for (let m = 0, n = policys.length; m < n; m++) {
                            const policyId = policys[m].policyId
                            const cabin = policys[m].cabin.cabinCode
                            if (flightItemPolicyId === policyId && flightItemCabin === cabin) {
                                flightItem.policys.splice(i, 1, policys[m])
                                // pIndex++
                                break
                            }
                        }
                    }
                }
                this.flightsList[index] = flightItem
                //this.$set(this.flightsList, index, flightItem)
                this.flightsList[index].isReload = true

                this.flightsList.forEach((value, index) => {
                    let rebatePrice = 0
                    let settlementPriceTaxes = 0
                    value.policys.forEach((value1, index1) => {
                        if (index1 === 0) {
                            rebatePrice = value1.priceData[0].rebatePrice
                            settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
                        } else {
                            if (rebatePrice > value1.priceData[0].rebatePrice) {
                                rebatePrice = value1.priceData[0].rebatePrice
                            }
                            if (settlementPriceTaxes > value1.priceData[0].settlementPriceTaxes) {
                                settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
                            }
                        }
                    })
                    value.rebatePrice = rebatePrice
                    value.settlementPriceTaxes = settlementPriceTaxes
                })
            }).catch(() => {
                item.loading = false
            })*/
        },
        init() {
            this.$refs.starCity.init({placeholder_text: "请选择出发城市"});
        },

        errorToGoIn() {
            let formData = JSON.parse(JSON.stringify(this.formData))
            this.$router.push({
                name: "airOrders",
                query: {
                    airdata: JSON.stringify(formData)
                }
            });
        },
        checkPriceAndGoNextPage(formData) {
            this.loading = true
            let checkFormData = JSON.parse(JSON.stringify(formData))
            //checkFormData.segmentList = checkFormData.segments
            //delete checkFormData.segments
            buyer_dom_white_detail(checkFormData).then(res => {
                this.$router.push({
                    name: "airOrders",
                    query: {
                        airdata: JSON.stringify(formData)
                    }
                });
            }, error => {
                console.log('error', error)
                if (error.data.code === '090401') {
                    // 单程失败
                    /*this.$message({
                        type: "warning",
                        message: error.data.msg
                    })
                    this.search()*/
                    this.errorText = error.data.msg
                    this.errorCode = error.data.code
                    this.dialogError3Visible = true
                    this.formData = formData

                } else if (error.data.code === '090310') {
                    // 往返第一程失败
                    /*this.$message({
                        type: "warning",
                        message: error.data.msg
                    })
                    this.segmentsList[0] = null
                    this.itemOne[0] = null
                    this.search()*/
                    this.errorText = error.data.msg
                    this.errorCode = error.data.code
                    this.dialogError3Visible = true
                    this.formData = formData
                } else if (error.data.code === '090301') {
                    // 往返第二程失败
                    /*this.$message({
                        type: "warning",
                        message: error.data.msg
                    })
                    this.segmentsList.splice(1,1)
                    this.itemOne.splice(1,1)
                    this.loading = true;
                    this.getflights(this.params);
                    this.initStatu++;*/
                    this.errorText = error.data.msg
                    this.errorCode = error.data.code
                    this.dialogError3Visible = true
                    this.formData = formData
                } else if (error.data.code === '090311') {
                    /*this.$message({
                        type: "warning",
                        message: error.data.msg
                    })
                    this.segmentsList = []
                    this.itemOne = []
                    this.search()*/
                    this.errorText = error.data.msg
                    this.errorCode = error.data.code
                    this.dialogError3Visible = true
                    this.formData = formData
                } else if (error.data.code === '000109') {
                    // 机票变价
                    this.errorText = error.data.msg
                    this.segmentsList = []
                    this.itemOne = []
                    this.dialogErrorVisible = true
                    this.returnSecond = 3
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.initSearch()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    this.errorText = error.data.msg
                    this.dialogError2Visible = true
                }
            }).catch((err) => {
                console.log('catch', err)
            }).finally(() => {
                this.loading = false
            })
        },
        errorToFlightBack() {
            this.dialogError3Visible = false
            if (this.errorCode === '090401') {
                this.search()
            } else if (this.errorCode === '090310') {
                this.segmentsList[0] = null
                this.itemOne[0] = null
                this.search()
            } else if (this.errorCode === '090301') {
                this.segmentsList.splice(1, 1)
                this.itemOne.splice(1, 1)
                this.loading = true;
                this.getflights(this.params);
                this.initStatu++;
            } else if (this.errorCode === '') {
                this.segmentsList = []
                this.itemOne = []
                this.search()
            }
        }
    },
    watch: {
        airType() {
            if (this.airType === 1) {
                this.time2Show = true;
            } else {
                this.time2Show = false;
            }
            if (this.airType === 2 && this.time1 > this.time2) {
                this.time2 = null;
            }
            this.airTypeShow = false;
            this.itemOne = [];
        },
        // 出发城市1
        starCity1(val) {
            let Starfocus = document.getElementById("starCity");
            if (this.starCity1 !== "") {
                this.starList = false;
            }
            if (Starfocus === document.activeElement && this.starCity1 === "") {
                this.starList = true;
                this.starCity = "";
                console.log(this.starCity, this.starCity1);
            }
        },
        // 目的城市1
        endCity1() {
            let endCity = document.getElementById("endCity");
            if (this.endCity1 !== "") {
                this.EndList = false;
            }
            if (endCity === document.activeElement && this.endCity1 === "") {
                this.EndList = true;
            }
        },
        // 出发城市2
        starTwoCity1() {
            let Starfocus = document.getElementById("starTwoCity");
            if (this.starTwoCity1 !== "") {
                this.starTwoList = false;
            }
            if (Starfocus === document.activeElement && this.starTwoCity1 === "") {
                this.starTwoList = true;
            }
        },
        // 目的城市2
        endTwoCity1() {
            let endCity = document.getElementById("endTwoCity");
            if (this.endTwoCity1 !== "") {
                this.EndTwoList = false;
            }
            if (endCity === document.activeElement && this.endTwoCity1 === "") {
                this.EndTwoList = true;
            }
        },
        // 获取国内出发城市
        startCityObj(newVal) {
            console.log(newVal)
            this.starCity = newVal ? newVal.code : ''
            this.starCity1 = newVal ? newVal.name : ''
        },
        // 获取国内目的城市
        endCityObj(newVal) {
            console.log(newVal)
            this.endCity = newVal ? newVal.code : ''
            this.endCity1 = newVal ? newVal.name : ''
        },
        loading(val) {
            this.flightShow.length = 0;
        },
        flightsList(val) {
            val.map((item, index) => {
                this.flightShow[index] = false;
            });
        }

    },
    mounted() {
        this.init()
        this.loadAllCity();
    },
    async created() {
        this.getAirStatus();
        this.getAirlines();
        if (this.$route.query.airdata) {
            let params = JSON.parse(this.$route.query.airdata);
            console.log('params', params)
            this.airType = params.airType;
            (this.time1 = params.time1), //  出发时间
                (this.time2 = params.time2), //  返回时间
                (this.time2Show = params.time2Show), // 返回时间是否可用
                // this.timeRoll =  params.timeRoll, //滚动条时间
                (this.startCityObj.code = params.starCity),
                (this.starCity = params.starCity), // 出发城市
                (this.starCity1 = params.starCity1), // 展示出发城市
                (this.starTwoCity = params.starTwoCity), // 第二出发城市
                (this.starTwoCity1 = params.starTwoCity1), // 第二展示出发城市
                (this.endCityObj.code = params.endCity),
                (this.endCity = params.endCity), // 目的城市
                (this.endCity1 = params.endCity1), // 展示目的城市
                (this.endTwoCity = params.endTwoCity), // 第二目的城市
                (this.endTwoCity1 = params.endTwoCity1), // 第二展示目的城市
                (this.psgType = params.psgType);
            (this.ClassType = params.ClassType), //舱位等级
                (this.airlines = params.airlines);
            this.saveWeek = this.$moment(params.time1).format("YYYY-MM-DD");
            this.flightNo = params.flightNo;
            this.cabin = params.cabin;
            this.accurateSearch = params.accurateSearch;
            this.search();
        }
    }
};
